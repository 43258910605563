import payload_plugin_oi7RiaeDOK from "/app/node_modules/.pnpm/@pinia+nuxt@0.7.0_magicast@0.3.5_rollup@4.25.0_typescript@5.6.3_vue@3.5.12_typescript@5.6.3__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_FYCNX3LcaP from "/app/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@9.14.0_jiti@2.4.0__ioredis@5.4._ey5zclehggdjfy5wydl6xnhzce/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_dDWA841QlV from "/app/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@9.14.0_jiti@2.4.0__ioredis@5.4._ey5zclehggdjfy5wydl6xnhzce/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_a9dgCkydQD from "/app/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@9.14.0_jiti@2.4.0__ioredis@5.4._ey5zclehggdjfy5wydl6xnhzce/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_7aqDe0JBMb from "/app/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@9.14.0_jiti@2.4.0__ioredis@5.4._ey5zclehggdjfy5wydl6xnhzce/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_tvdPF5sr8J from "/app/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@9.14.0_jiti@2.4.0__ioredis@5.4._ey5zclehggdjfy5wydl6xnhzce/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_3t11LAGrP4 from "/app/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@9.14.0_jiti@2.4.0__ioredis@5.4._ey5zclehggdjfy5wydl6xnhzce/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_OMqvPQ2R8f from "/app/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@9.14.0_jiti@2.4.0__ioredis@5.4._ey5zclehggdjfy5wydl6xnhzce/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_2ZWskWUYmK from "/app/node_modules/.pnpm/@pinia+nuxt@0.7.0_magicast@0.3.5_rollup@4.25.0_typescript@5.6.3_vue@3.5.12_typescript@5.6.3__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_1ZPvIjmktt from "/app/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@9.14.0_jiti@2.4.0__ioredis@5.4._ey5zclehggdjfy5wydl6xnhzce/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_yXm34t5y6f from "/app/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2_magicast@0.3.5_rollup@4.25.0_webpack-sources@3.2.3/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_witv2UyUxx from "/app/node_modules/.pnpm/@nuxt+icon@1.6.1_magicast@0.3.5_rollup@4.25.0_vite@5.4.11_@types+node@22.9.0_sass@1.80.6_ters_r6pfh5qcjaabkf7du4udom4sse/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import rest_basic_4Jv3WZOath from "/app/node_modules/.pnpm/@bg-dev+nuxt-directus@2.4.5_@vue+apollo-composable@4.2.1_@apollo+client@3.11.10_graphql-ws@5._zljqe4zjt7da36xij5gfc3azam/node_modules/@bg-dev/nuxt-directus/dist/runtime/plugins/rest.basic.mjs";
import plugin_6Ph1iQzNHk from "/app/node_modules/.pnpm/@formkit+auto-animate@0.8.2/node_modules/@formkit/auto-animate/nuxt/runtime/plugin.mjs";
import lenis_client_iqfJeXHsbL from "/app/layers/base/plugins/lenis.client.ts";
export default [
  payload_plugin_oi7RiaeDOK,
  revive_payload_client_FYCNX3LcaP,
  unhead_dDWA841QlV,
  router_a9dgCkydQD,
  payload_client_7aqDe0JBMb,
  navigation_repaint_client_tvdPF5sr8J,
  check_outdated_build_client_3t11LAGrP4,
  chunk_reload_client_OMqvPQ2R8f,
  plugin_vue3_2ZWskWUYmK,
  components_plugin_KR1HBZs4kY,
  prefetch_client_1ZPvIjmktt,
  plugin_client_yXm34t5y6f,
  plugin_witv2UyUxx,
  rest_basic_4Jv3WZOath,
  plugin_6Ph1iQzNHk,
  lenis_client_iqfJeXHsbL
]