import { default as indexVSZNRxKL84Meta } from "/app/pages/catalog/[mark]/[model]/[generation]/[modification]/[car]/index.vue?macro=true";
import { default as indexlNm7Ki3m3xMeta } from "/app/pages/catalog/[mark]/[model]/[generation]/[modification]/index.vue?macro=true";
import { default as index2JZDcooYNpMeta } from "/app/pages/catalog/[mark]/[model]/[generation]/index.vue?macro=true";
import { default as indexg1vgziNdNNMeta } from "/app/pages/catalog/[mark]/[model]/index.vue?macro=true";
import { default as index2YNOeF2hnhMeta } from "/app/pages/catalog/[mark]/index.vue?macro=true";
import { default as indexvJKqjSbZLzMeta } from "/app/pages/catalog/index.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
export default [
  {
    name: "catalog-mark-model-generation-modification-car",
    path: "/catalog/:mark()/:model()/:generation()/:modification()/:car()",
    component: () => import("/app/pages/catalog/[mark]/[model]/[generation]/[modification]/[car]/index.vue")
  },
  {
    name: "catalog-mark-model-generation-modification",
    path: "/catalog/:mark()/:model()/:generation()/:modification()",
    component: () => import("/app/pages/catalog/[mark]/[model]/[generation]/[modification]/index.vue")
  },
  {
    name: "catalog-mark-model-generation",
    path: "/catalog/:mark()/:model()/:generation()",
    component: () => import("/app/pages/catalog/[mark]/[model]/[generation]/index.vue")
  },
  {
    name: "catalog-mark-model",
    path: "/catalog/:mark()/:model()",
    component: () => import("/app/pages/catalog/[mark]/[model]/index.vue")
  },
  {
    name: "catalog-mark",
    path: "/catalog/:mark()",
    component: () => import("/app/pages/catalog/[mark]/index.vue")
  },
  {
    name: "catalog",
    path: "/catalog",
    component: () => import("/app/pages/catalog/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/app/pages/index.vue")
  }
]