import Lenis from 'lenis'

export default defineNuxtPlugin(nuxtApp => {
  const createLenisForElement = (element: HTMLElement) => {
    const lenis = new Lenis({
      duration: 0.8,
      easing: (t: number) => 1 - Math.pow(1 - t, 3),
      orientation: 'vertical', // vertical, horizontal
      gestureOrientation: 'vertical', // vertical, horizontal, both
      smoothWheel: true,
      wheelMultiplier: 1,
      syncTouch: true,
      touchMultiplier: 2,
      infinite: false,
      autoRaf: false,
      overscroll: true,
      wrapper: element,
      content: element,
    })

    function raf(time: number) {
      lenis.raf(time)
      requestAnimationFrame(raf)
    }
    requestAnimationFrame(raf)

    return lenis
  }

  const lenisForBody = createLenisForElement(document.body)

  nuxtApp.provide('lenis', lenisForBody)
  nuxtApp.provide('createLenisForElement', createLenisForElement)
})
